.joinHeader {
  // font-family: 'Myriad Pro' !important;
  font-family: 'Gill Sans Nova' !important;
  font-size: 32px !important;
  font-weight: 900 !important;
  line-height: 41.66px !important;
  letter-spacing: 0.03em !important;
  text-align: center !important;
  align-items: center !important;
  margin-bottom: 10px !important;
  //   color: #05232e !important;
  // color: #fff !important;
  // color: #76d9ff !important;
  color: #ECA43C;
}
.joinSubtitles {
  // font-family: 'Myriad Pro' !important;
  font-family: 'Gill Sans' !important;
  font-size: 12px !important;
  width: 70px !important;
  // font-size: 24px !important;
  font-weight: 400 !important;
  line-height: 29px !important;
  letter-spacing: 0em !important;
  text-align: center !important;
  // padding-left: 10px !important;
  //   color: #05232e !important;
  color: #fff !important;
}
.joinSubtitleOr {
  font-family: 'Gill Sans' !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  text-align: center !important;
  line-height: 22px !important;
  // padding: 10px !important;
  letter-spacing: 0em !important;
  //   color: #05232e !important;
  color: #fff !important;
}

.daterIcon {
  font-family: 'Knuckle Down' !important;
  // font-size: 40px;
  font-weight: 400 !important;
  // line-height: 40px;
  letter-spacing: 0em !important;
}

.centralLayout {
  // background-color: #000 !important;
  color: #fff !important;
}

.joinSubHeader {
  font-family: 'Gill Sans Nova' !important;
  font-size: 13px !important;
  font-weight: 500  !important;
  line-height: 16px !important;
  letter-spacing: 0.16em !important;
  // text-align: left !important;
  padding-top: 20px !important;
  width: 85px !important;
  color: #cccccc !important;

  }
  .joinSubHeaderOr{
    font-family: 'Gill Sans Nova' !important;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.12em;
    padding-top: 25px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    text-align: center;
    color: #808184;
    
  }

  .daterButton{
    background-color: #E66433 !important;
    border-radius: 25px !important;
    font-family: 'Gill Sans Nova' !important;
    font-weight: 900 !important;
    text-transform: inherit !important;
    font-size: 16px !important; 
    color: #FFFFFF !important;
    padding: 5px 50px !important;
  }

  .friendButton{
    background-color: #2BB673 !important;
    border-radius: 25px !important;
    font-family: 'Gill Sans Nova' !important;
    font-weight: 900 !important;
    text-transform: inherit !important;
    font-size: 16px !important; 
    color: #FFFFFF !important;
    padding: 5px 50px !important;
  }
