.description {
  // color: #00597b !important;
  // color: #fff !important;
  color: #eca43c !important;
  font-family: 'Gill Sans Nova' !important;
  font-style: normal !important;
  font-weight: 900 !important;
  line-height: 1.5 !important;
}

.logoutDesc {
  p {
    font-family: 'Gill Sans Nova' !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    // word-spacing: 3px !important;
    text-transform: uppercase !important;
    line-height: 16px !important;
    letter-spacing: 0.1em !important;
    margin-bottom: 30px;
  }
}

.error {
  color: #E62A33 !important;
  line-height: 17.21px !important;
  letter-spacing: 0.12em !important;
  margin-left: 12px !important;
font-family: 'Myriad Pro' !important;
  // font-size: 18px !important;
  font-style: italic !important;
  font-weight: 400 !important;
  // line-height: 40px !important; /* 166.667% */
  text-align: left !important;
}
.cancelTxt {
  text-transform: none !important;
  font-size: 14px !important;
  font-weight: 900 !important;
  font-family: 'Gill Sans Nova' !important;
  color: #ffffff !important;
  background-color: #808184 !important;
  border-radius: 50px !important;
  padding: 8px 41px !important;
  line-height: 18.22px;
}

.logoutBtn {
  font-size: 14px !important;
  font-weight: 900 !important;
  text-transform: none !important;
  font-family: 'Gill Sans Nova' !important;
  color: #ffffff !important;
  background-color: #e66433 !important;
  border-radius: 50px !important;
  padding: 8px 25px !important;
}
@media (width>=1800px) {
  .description {
    font-size: 56px !important;
  }
  .logoutDesc {
    p {
      font-size: 32px !important;
      font-family: 'Gill Sans' !important;
    }
  }
  .cancelTxt {
    font-size: 24px !important;
  }
  .logoutBtn {
    font-size: 20px !important;
    padding: 20px 30px !important;
  }
}
