.heading{
    //styleName: Heading S 2;
font-family: 'Gill Sans Nova' !important;
font-weight: 900 !important;
letter-spacing: 0em !important;
text-align: center !important;
color:#E66433 !important
}

.message{
    font-family: 'Gill Sans Nova' !important;
    font-weight: 500 !important;
// line-height: 16px !important;
letter-spacing: 0.04em !important;
text-align: center;
text-transform: uppercase ;
color: #44C8F5 !important;
white-space: pre-line !important;
}

.button{
    display: flex !important;
    border-radius: 25px !important;
   padding: 10px 15px !important;
    justify-content: center !important;
    align-items: center !important;
    font-family: 'Gill Sans Nova' !important;
   // font-size: 18px !important;
    font-style: normal !important;
    font-weight:900 !important;
   // line-height: 24px !important; /* 133.333% */
    text-transform: capitalize !important;  
    color: #ffffff !important;
}
.buttonCancel{
    display: flex !important;
    border-radius: 25px !important;
   padding: 10px 32px !important;
    justify-content: center !important;
    align-items: center !important;
    font-family: 'Gill Sans Nova' !important;
   // font-size: 18px !important;
    font-style: normal !important;
    font-weight:900 !important;
   // line-height: 24px !important; /* 133.333% */
    text-transform: capitalize !important;  
    color: #ffffff !important;
}
.primary{
    background-color: #F9AB47 !important;
    color: #ffffff !important;
}

.secondary{
    background-color: #BCBEC0 !important;
    color: #ffffff !important;
}