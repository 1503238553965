.heading {
  // color: #444 !important;
  color: #F4AA4D !important;
  // text-align: center !important;
  /* Heading */
  font-family: 'Gill Sans Nova' !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 900 !important;
  line-height: normal !important;
  text-transform: capitalize !important;
  // font-size: 32px !important;
  // margin: 10px !important;
}
.error {
  color: #ff0000 !important;
  line-height: 17.21px !important;
  letter-spacing: 0.12em !important;
  margin-left: 12px !important;



font-family: 'Myriad Pro' !important;
  // font-size: 18px !important;
  font-style: italic !important;
  font-weight: 400 !important;
  // line-height: 40px !important; /* 166.667% */
  text-align: left !important;
}

.closeIcon {
}

@media (width>=1800px) {
  .heading {
    font-size: 18px !important;
  }
}

@media (width>=1000px) {
  .closeIcon {
    margin-right: -55px !important;
  }
}
