.landingPage {
  background-color: #000 !important;
  min-height: 100vh !important;
}

.banner {
  //   background-color: #00597b !important;
  background-color: #000 !important;
}

.bannerHeading {
  color: #fff !important;
  font-family: 'Gill Sans Nova' !important;
font-size: 24px !important;
font-weight: 500 !important;
line-height: 30px !important;
letter-spacing: 0.1em !important;
text-transform: uppercase;
  // color: #76d9ff !important;
  // font-size: 40px !important;
  // font-weight: 400 !important;
  // line-height: normal !important;
}

.bannerDescription {
  font-family: 'Gill Sans Nova' !important;
font-size: 14px !important;
font-weight: 500 !important;
line-height: 18px !important;
letter-spacing: 0.09em !important;
  color: #fff !important;
  text-transform: uppercase;
}

.triangle {
  width: 0;
  height: 0;
  margin: 40%;
  margin-top: 178%;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-top: 50px solid #181b20;
}

@media (width>=1800px) {
  .bannerDescription {
    font-size: 32px !important;
  }
}

.downloadApp {
  color: #F8A233 !important;
  font-family: 'Gill Sans Nova' !important;
  font-style: normal !important;
  font-weight: 900 !important;
  text-transform: capitalize !important;
  font-size: 12px !important;
  letter-spacing: 0.03em !important;
}

.downloadAppInstall {
  color: #BCBEC0 !important;
  font-family: 'Gill Sans Nova' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  text-transform: uppercase !important;
  font-size: 12px !important;
  letter-spacing: 0.06em !important;

}

.name {
  color: #4D4D4F !important;
  border-radius: 50px !important;
  font-family: 'Gill Sans Nova' !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 17.21px !important;
  letter-spacing: 0.12em !important;
}

.message {
  padding-top: 10px;
  color: #4D4D4F !important;
  border-radius: 16px !important;
  font-family: 'Gill Sans Nova' !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 17.21px !important;
  letter-spacing: 0.12em !important;
}

.howDoesItWork {
  //styleName: Heading S 2;
  font-family: 'Gill Sans Nova' !important;
  font-weight: 400 !important;
  letter-spacing: 0em !important;
  text-align: center !important;
  //   color: #232233 !important;
  color: #fff !important;
}

.howDoesItWorkDesc {
  //styleName: Heading S 2;
  font-family: 'Gill Sans Nova' !important;
  font-weight: 400 !important;
  text-align: center !important;
  //   color: #05232e !important;
  //   color: #fff !important;
  background-color: #000 !important;
  color: #fff !important;
}

@media (width>=1800px) {
  .howDoesItWorkDesc {
    font-size: 30px !important;
    line-height: normal !important;
    background-color: #000 !important;
    color: #fff !important;
  }
  .howDoesItWorkDesc::placeholder {
    font-size: 30px !important;
  }
}

.capabilitiesHeader {
  //styleName: Heading S 2;
  font-family: 'Gill Sans Nova' !important;
  font-weight: 400 !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: #232233 !important;
}

.capabilitiesDescription {
  font-family: 'Gill Sans Nova' !important;
  font-weight: 400 !important;
  text-align: left !important;
  //   color: #05232e !important;
  color: #fff !important;
  font-size: 18px;
}

.accordionSummary {
  //styleName: Heading;
  font-family: 'Gill Sans Nova' !important;
  font-weight: 600 !important;
  letter-spacing: 0em !important;
  text-align: left !important;
}

.accordionDescription {
  //   color: #000 !important;
  color: #fff !important;
  background-color: #000 !important;
  font-family: 'Gill Sans Nova' !important;
  font-style: normal !important;
  font-weight: 400 !important;
}

.contactUsLabel {
  font-family: 'Gill Sans Nova' !important;
  font-weight: 400 !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: '#333333' !important;
}

.landingHeader {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.chatterboxTxtWrapper {
  display: flex;

  .chatterBoxImg {
    width: 45%;
    align-items: center;

    img {
      width: 100%;
    }
  }

  .capabilityHeading {
    font-family: 'Gill Sans Nova';
  }
}

.faqLandingWrapper {
  .howDoesItWorkDesc {
    background-color: #000 !important;
    color: #fff !important;
    padding: 0px 200px;
  }
}

.landingContactWrapper {
  .howDoesItWorkDescHead {
    // padding-top: 10px;
  }
}

.appStoreImg {
  cursor: pointer;
  width: 250px;

  // width: 50%;
  img {
    width: 100%;
  }
}

@media (width <=1100px) {
  .landingHeader {
    flex-direction: column !important;
    padding: 50px !important;

    .firstHeaderGrid {
      width: 100% !important;
    }

    .secHeaderGrid {
      width: 100% !important;
      justify-content: center !important;
    }
  }

  .mobPadding {
    padding: 10px 20px !important;
  }

  .chatterboxTxtWrapper {
    display: flex;
    flex-direction: column !important;
    align-items: center;

    .chatterBoxImg {
      width: 100% !important;
      max-width: none !important;
      align-items: center;

      img {
        width: 100%;
      }
    }

    .capabilityHeading {
      font-family: 'Gill Sans Nova';
    }

    .chatterBoxImgTxt {
      max-width: none !important;
      width: 100%;
    }
  }

  .landing2Img {
    width: 100%;
  }

  .faqLandingWrapper {
    .howDoesItWorkDesc {
      background-color: #000 !important;
      color: #fff !important;
      padding: 0px 100px !important;
    }
  }

  .landingContactWrapper {
    .howDoesItWorkDescHead {
      background-color: #000 !important;
      color: #fff !important;
      padding: 10px 50px;
    }
  }
}

@media (width >=1800px) {
  .capabilitiesDescription {
    font-size: 26px !important;
  }

  .howDoesItWork {
    font-size: 42px !important;
  }

  .capabilityHeading {
    font-size: 42px !important;
  }

  .accordionSummary {
    font-size: 32px !important;
  }

  .accordionDescription {
    font-size: 26px !important;
  }

  .howDoesItWorkDescHead {
    font-size: 26px !important;
  }

  .bannerHeading {
    font-size: 56px !important;
  }

  .submitBtn {
    button {
      padding: 20px 30px !important;
      font-size: 32px !important;
      line-height: 24px !important;
    }
  }
}
