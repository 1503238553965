.header {
  // color: #000 !important;
  // color: #fff !important;
  color: #76d9ff !important;
  font-family: 'Myriad Pro' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  font-size: 18px !important;
}

.description {
  // color: #333 !important;
  // color: #fff !important;
  color: #fff !important;
  font-family: 'Gill Sans Nova' !important;
  //font-size: 24px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  text-transform: uppercase !important;
}

.shareApp {
  // color: #444 !important;
  color: #e66433 !important;
  /* Heading */
  font-family: 'Gill Sans Nova' !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 900 !important;
  line-height: normal !important;
  text-transform: capitalize !important;
}

.sectionHeader {
  //   color: #000 !important;
  // color: #fff !important;
  color: #76d9ff !important;
  /* Large Lebel */
  font-family: 'Myriad Pro' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  font-size: 18px !important;
}

.container {
  border-radius: 16px !important;
  //   background: #f6f6f6 !important;
  background: #000 !important;
  padding: 0px 0px 16px 16px;
}
.items {
  //   color: #000 !important;
  color: #fff !important;
  /* Large Lebel */
  font-family: 'Myriad Pro' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}
.smsWrapper {
  // width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding-right: 60px;
}

@media (width>=1800px) {
  .header {
    font-size: 48px !important;
  }
  .sectionHeader {
    font-size: 28px !important;
  }
  .items {
    font-size: 24px !important;
  }
  .delBtn {
    button {
      padding: 20px 30px !important;
      font-size: 28px !important;
    }
  }
}
