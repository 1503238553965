.subHeader {
  // color: #00597b !important;
  // color: #fff !important;
  color: #E66433 !important;
  /* Heading S 3 */
  font-family: 'Gill Sans Nova' !important;
  // font-size: 24px !important;
  font-style: normal !important;
  font-weight: 900 !important;
  line-height: normal !important;
  width: 100% !important;
  text-align: left !important;
}
.myProfileImgWrapper {
  img {
    height: 470px;
  }
}
.myProfileAboutFirstSectionWrapper {
  div {
    align-items: center;
  }
}
.myProfileFriendName {
  img {
    // object-fit: contain !important;
  }
}
.myprofileabtusProfile {
  width: 477px;
}

.stackClass {
  margin-top: 10px !important;
}

.friendName {
  //styleName: Heading;
  font-family: 'Gill Sans Nova' !important;
  // font-size: 24px !important;
  font-weight: 900 !important;
  //line-height: 29px !important;
  letter-spacing: 0em !important;
  text-align: center !important;
  // color: #00597b !important;
  color: #fff !important;
}

.profilepicContainer {
  max-width: 500px !important;
}

.myFriends {
  //styleName: Large Heading;
  font-family: 'Myriad Pro' !important;
  // font-family: 'Knuckle Down' !important;
  // font-size: 32px !important;
  font-weight: 500 !important;
  // line-height: 40px !important;
  letter-spacing: 0em !important;
  text-align: center !important;
  // color: #00597b !important;
  // color: #fff !important;
  color: #76d9ff !important;
}

.myProfileAboutFirstSection {
  width: 25%;
  div {
    // align-items: flex-start;
  }
}

.maybNoYesbtnWrapper {
  .noBtnWrapper {
    width: 25%;

    button {
      width: 100%;
    }
  }

  .yesBtnWrapper {
    // width: 25%;
    width: auto;

    button {
      width: 100%;
    }
  }

  .mayBeBtnWrapper {
    width: 23%;

    button {
      width: 100%;
    }
  }
}

.reachOut {
  // color: #00597b !important;
  // color: #fff !important;
  color: #76d9ff !important;
  /* Heading S 3 */
  font-family: 'Knuckle Down' !important;
  // font-size: 24px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  width: 100% !important;
  text-align: center !important;
}

.friendName_m {
  //styleName: Heading;
  font-family: 'Myriad Pro' !important;
  font-size: 20px !important;
  font-weight: 900 !important;
  line-height: 29px !important;
  letter-spacing: 0em !important;
  text-align: center !important;
  // color: #00597b !important;
  color: #fff !important;
}

.myFriends_m {
  //styleName: Large Heading;
  font-family: 'Myriad Pro' !important;
  font-size: 28px !important;
  font-weight: 900 !important;
  line-height: 40px !important;
  letter-spacing: 0em !important;
  text-align: center !important;
  // color: #00597b !important;
  color: #fff !important;
}

.reachOut_m {
  // color: #00597b !important;
  color: #fff !important;

  /* Heading S 3 */
  font-family: 'Knuckle Down' !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  width: 100% !important;
  text-align: center !important;
}

.exploreButton {
  border-radius: 40px !important;
  color: #fff !important;
  font-family: 'Gill Sans Nova' !important;
  font-style: normal !important;
  font-weight: 900 !important;
  line-height: normal !important;
  text-transform: none !important;
  font-size: 14px !important;
}

.myprofileImgAbtWrapper {
  display: flex;
  gap: 10px;
  align-items: center !important;

  .myProfileImg {
    max-width: 40%;
    // width: 45% !important;

    img {
      width: 100% !important;
      border-radius: 15px !important;
    }
  }

  .myProfileAbtUs {
    width: 52%;
  }
}

.myProfileAboutMe {
  width: 100%;
  // display: flex;
  gap: 10px;
  align-items: center !important;
}

.myProfileAboutFirstSection {
  width: 25%;

  img {
    width: 100%;
    // object-fit: contain;
  }
}

.myProfileAboutSecSection {
  width: 25%;
  // padding: 10px;
  padding: 26px;
}

.myProfileAboutThirdSection {
  width: 25%;
  // align-items: flex-start;

  img {
    width: 100%;
  }
}
@media (min-width: 800px) and (max-width: 1220px) {
  .myprofileabtusProfile {
    height: 420px !important;
  }
}

@media (min-width: 1200px) and (max-width: 1366px) {
  .myProfileImg {
    width: 100% !important;
    max-width: 50% !important;
  }
  .myProfileAbtUs {
    width: 45% !important;
  }
}
@media (width<=1199px) {
  .myprofileImgAbtWrapper {
    display: flex;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;

    .myProfileImg {
      width: 100%;
      max-width: unset !important;
      object-fit: inherit !important;

      img {
        width: 100% !important;
        height: 80% !important;
      }
    }

    .myProfileAbtUs {
      width: 100%;
    }
  }
  .myProfileImgWrapper {
    img {
      height: 292px !important;
      object-fit: cover !important;
    }
  }
  .myProfileAboutFirstSection {
    //width: 30%;

    img {
      width: 80px;
      height: 80px;
      object-fit: cover !important;
    }

    margin-top: 10px;
  }

  .myProfileAboutSecSection {
    width: 30%;
    padding: 20px !important;
  }

  .myProfileAboutThirdSection {
   // width: 30%;

    img {
      width: 80px;
      height: 80px;

    }
  }

  .maybNoYesbtnWrapper {
    .noBtnWrapper {
      width: 30%;

      button {
        width: 100%;
      }

    }

    .yesBtnWrapper {
      // width: 30%;
      width: auto;

      button {
        width: 100%;
      }
    }

    .mayBeBtnWrapper {
      width: 29%;

      button {
        width: 100%;
      }
    }
    margin-top: 15px !important;
    
  }
}
// @media (width<=780px){
//     .myProfileFriendName{
//         img{
//             object-fit: contain !important;
//         }
//     }
// }

.myFriends {
  font-size: 22px !important;
}

.reachOut {
  font-size: 20px !important;
}

@media (width>=1800px) {
  // .subHeader {
  //   font-size: 42px !important;
  // }

  .myFriends {
    font-size: 32px !important;
  }

  .myProfileAboutMe {
    // width: 60%;
  }
}

.myProfileAboutSecSection {
  // padding: 10px;
  padding: 26px;
}

@media (width>2200px) {
  .myFriends {
    font-size: 26px !important;
  }

  .reachOut {
    font-size: 24px !important;
  }

  .myProfileAboutFirstSection {
    width: 20% !important;

    img {
      width: 100%;
    }
  }

  .myProfileAboutSecSection {
    width: 20% !important;
    padding: 26px;
  }

  .myProfileAboutThirdSection {
    width: 20% !important;
    img {
      width: 100%;
    }
  }

  .maybNoYesbtnWrapper {
    .noBtnWrapper {
      width: 20%;

      button {
        width: 100%;
      }
    }

    .yesBtnWrapper {
      // width: 20%;
      width: auto;

      button {
        width: 100%;
      }
    }

    .mayBeBtnWrapper {
      width: 19%;

      button {
        width: 100%;
      }
    }
  }
}

// @media (width<=900px) {
//   .myProfileImgWrapper {
//       img {
//           height: 100% !important;
//       }
//   }
// }

// @media (min-width: 575px) and (max-width: 1200px){
//     .myProfileImgWrapper{
//         img{
//             height: 480px !important;
//         }
//     }
// }
// @media (min-width: 375px) and (max-width: 574px){
//     .myProfileImgWrapper{
//         img{
//             height: 350px !important;
//         }
//     }
// }
