.container {
  // height: 395px;
  // background-color: #FFFFFF;
  background-color: #000 !important;
  padding: 48px;
  // padding-bottom: 48px;
  gap: 24px;
  max-height: 90vh !important;
  height: 75vh;
  overflow: auto;
  margin-top: 5vh !important;
}
@media (width <=500px) {
  .container {
    padding: 0px !important;
  }
}
