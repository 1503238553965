.menuContainer {
  text-align: right !important;
  justify-content: flex-end !important;
  background-color: #000000 !important;
  gap: 6px;
  max-width: fit-content;
  li {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}
.header {
  padding-top: 31px;
  // background-color: #ffffff !important;
  background-color: #000000 !important;
  box-shadow: none !important;
  position: relative; // Needed for absolute positioning of the pseudo-element
  &::after { // Pseudo-element for the bottom border
    content: ""; // Corrected syntax, removed quotes
    position: absolute;
    bottom: 0;
    left: 20px; // Corrected syntax, removed quotes
    right: 20px; // Corrected syntax, removed quotes
    border-bottom: 1px solid #cccccc !important; // Corrected property name to CSS standard
  }
}
.appIcon {
  // padding-top: 24px;
  // padding-bottom: 24px;
  // width: 189px;
  width: auto;
  cursor: pointer !important;
}

.menuItem {
  //styleName: Large Lebel;
  font-family: 'Myriad Pro' !important;
  // font-size: '18px' !important;
  font-weight: 600 !important;
  //  line-height: '22px' !important;
  letter-spacing: 0em !important;
  text-align: center !important;
  // color: #05232E !important;
  // padding-left: 5px !important;
}
.appIconWrapper {
 // padding-left: 16px;
  // padding-left: 40px;
}

@media (min-width: 600px) and (max-width: 750px) {
  .headerToolbar {
    padding-right: 5px !important;
    padding-left: 5px !important;
  }
  .appIcon {
    // width: 150px !important;
    width: auto !important;
  }
  .menuContainer {
    gap: 10px;
    color: #76d9ff !important;
    padding-right: 10px;
    li {
      padding-right: 0px !important;
      padding-left: 0px !important;
    }
  }
  .appIconWrapper {
    // padding-left: 0px !important;
  }
}

@media (width<=700px) {
  .appIconWrapper {
    display: flex;
    justify-content: center;
    //padding-left: 75px !important;
  }
  .appIcon{
    height: 44px !important;
  }
}
