.description {
  // color: #00597b !important;
  color: #76d9ff !important;
  font-family: 'Gill Sans Nova' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.subHeader {
  // color: #00597b !important;
  color: #f4aa4d !important;

  /* Heading S 3 */
  font-family: 'Gill Sans Nova' !important;
  // font-size: 24px !important;
  font-style: normal !important;
  font-weight: 900 !important;
  line-height: normal !important;
  width: 100% !important;
  cursor: pointer !important;
  text-align: left !important;
  font-size: 16px !important;
  //line-height: 15.62px !important;
  letter-spacing: 0.08em !important;
}
.subHeaderAns {
  font-size: 16px !important;
  color: #ffffff !important;
  font-family: 'Gill Sans Nova' !important;
  font-weight: 400 !important;
  line-height: 14.4px !important;
  letter-spacing: 0.06em !important;
}
.btn {
  background-color: #00597b !important;
}

.error {
  color: #e62a33 !important;
  line-height: 17.21px !important;
  letter-spacing: 0.12em !important;
  margin-left: 12px !important;

  font-family: 'Myriad Pro' !important;
  // font-size: 18px !important;
  font-style: italic !important;
  font-weight: 400 !important;
  // line-height: 40px !important; /* 166.667% */
  text-align: left !important;
}

@media (width>=1800px) {
  .subHeaderAns {
    font-size: 12px !important;
  }
  .subHeader {
    font-size: 14px !important;
  }
}
