.inputField{
    border-bottom: 1px solid #C4C4C4 !important;
    background: #F1F6F8 !important;
    //padding-left:16px;
    font-family: 'Myriad Pro' ;
   // font-size: 20px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
    padding-left: 13px;

}

.inputDateField{
    padding-bottom: 15px !important;
    padding-top: 0px !important;
}