.description {
  color: #ECA43C !important;
  font-family: 'Gill Sans Nova' !important;
  word-spacing: 5px !important;
  // font-size: 24px !important;
  font-style: normal !important;
  font-weight: 900 !important;
  // line-height: 40px !important; /* 166.667% */
  text-align: left !important;
}

.name {
  color: #4D4D4F !important;
  border-radius: 16px !important;
  font-family: 'Gill Sans Nova' !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 17.21px !important;
  letter-spacing: 0.12em !important;
}
.error {
  color: #ff0000 !important;
  line-height: 17.21px !important;
  letter-spacing: 0.12em !important;
  margin-left: 12px !important;



font-family: 'Gill Sans Nova' !important;
  // font-size: 18px !important;
  font-style: italic !important;
  font-weight: 400 !important;
  // line-height: 40px !important; /* 166.667% */
  text-align: left !important;
}
.back {
  // color:  #00597B !important;
  color: #cccccc !important;
  font-family: 'Gill Sans Nova' !important;
  // font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  text-transform: uppercase;
  // padding-left: 10px !important;
  // padding-bottom: 5px !important;
  cursor: pointer;

  line-height: 17.21px;
  letter-spacing: 0.1em;
}

.logoBack{
  color: #cccccc;
  height: 12px !important;
  width: 12px !important;
}
