.description {
  // color: #05232E !important;
  // color: #fff !important;
  // background-color: #fff;
  color: #ffffff !important;
  color:#ECA43C !important;
  font-family: 'Gill Sans Nova' !important;
  font-size: 22px !important;
  width: 250px;
  font-style: normal !important;
  font-weight: 900 !important;
  // text-transform: uppercase !important;
  line-height: 24px !important; /* 166.667% */
  text-align: left !important;
}

.descriptionMessage {
  // color: #05232E !important;
  // color: #fff !important;
  // background-color: #fff;
  color: #ffffff !important;
  font-family: 'Gill Sans Nova' !important;
  font-size: 18px !important;
  width: 250px;
  font-style: normal !important;
  font-weight: 500 !important;
  text-transform: uppercase !important;
  line-height: 24px !important; /* 166.667% */
  text-align: left !important;
}

.error {
  color: #e62a33 !important;
  font-family: 'Gill Sans Nova' !important;
  // font-size: 18px !important;
  font-style: italic !important;
  font-weight: 500 !important;
  // line-height: 40px !important; /* 166.667% */
  text-align: left !important;
  line-height: 17.21px !important;
  letter-spacing: 0.12em !important;
  margin-top: 10px !important;
}
.centerOnSmallScreen {
  width: 33%;
}
.mobileInputDiv {
  width: 67%;
  padding-left: 15px;
}

.inputMobileNumber {
  border-bottom: 1px solid #c4c4c4 !important;
  background-color: #ffffff !important;
  border-radius: 20px !important;
  // height: 40px !important;
  // color: #fff !important;
  // color: #9D9FA2 !important;
  // font-size: 12px !important;
  font-family: 'Gill Sans Nova' !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 300 !important;
  line-height: normal !important;
  min-height: 36px !important;
  padding-left: 13px !important;
  // padding-top: 4px !important;
}
.back {
  // color:  #00597B !important;
  color: #cccccc !important;
  font-family: 'Gill Sans Nova' !important;
  // font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  text-transform: uppercase;
  // padding-left: 10px !important;
  // padding-bottom: 5px !important;
  cursor: pointer;

  line-height: 17.21px;
  letter-spacing: 0.1em;
}
.nextButton {
  background-color: #f8a233 !important;
}
.logoBack{
  color: #cccccc;
  height: 12px !important;
  width: 12px !important;
}
.logo{
  height: 18px !important;
  width: 18px !important;
}
// @media (width>=1800px) {
//   .description {
//     font-size: 32px !important;
//     line-height: unset !important;
//   }
//   .nextBtn {
//     button {
//       padding: 20px 30px !important;
//       font-size: 32px !important;
//     }
//   }
// }

// @media (min-width: 620px) and (max-width: 1024px) {
//   .centerOnSmallScreen {
//     width: 25%;
//   }
//   .mobileInputDiv {
//     width: 75%;
//   }
// }

// @media (min-width: 830px) and (max-width: 1024px) {
//   .centerOnSmallScreen {
//     width: 30%;
//   }
//   .mobileInputDiv {
//     width: 70%;
//   }
// }

// @media (width<620px) {
//   .centerOnSmallScreen {
//     width: 32%;
//   }
//   .mobileInputDiv {
//     width: 68%;
//   }
// }
