.button {
  display: flex !important;
  border-radius: 25px !important;
  // padding: 12px 32px !important;
  justify-content: center !important;
  align-items: center !important;
  font-family: 'Gill Sans Nova' !important;
  // font-size: 18px !important;
  font-style: normal !important;
  font-weight: 900 !important;
  // line-height: 24px !important; /* 133.333% */
  text-transform: capitalize !important;
}

.customBtnFont {
  font-size: 14px !important;
}
.primary {
  
  background-color: #e66433 !important;
  color: #ffffff !important;
  font-family: 'Gill Sans Nova' !important;
  font-weight: 900 !important;
}

.nextButton {
  font-family: 'Gill Sans Nova';
  font-size: 16x !important;
  font-weight: 900 !important;
  line-height: 18.22px !important;
  background-color: #e66433 !important;
  color: #ffffff !important;
}

.secondary {
  background-color: #33196b;
  color: #ffffff;
}

.custom {
  color: #000;
}
.yes {
  background-color: #3ab54a !important;
  color: #ffffff !important;
}
.no {
  background-color: #fe0000 !important;
  color: #ffffff !important;
}
.outlined {
  border-color: #e66433 !important;
  color: #000 !important;
}
@media (width>=1800px) {
  .customBtnFont {
    font-size: 24px !important;
  }
}
