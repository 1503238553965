.root {
  // background-color: #F6F6F6 !important;
  background-color: #000 !important;
  //padding-top: "15px" !important;
  display: flex;
  justify-content: space-between;
  // gap: 10px;
    // padding-right: 15px;
    // padding-left: 15px;
    // padding-bottom: 15px;
}
.firstDiv {
  display: flex;
  gap: 5px;
  width: 65%;
}
.profileName {
  //   color: #00597b !important;
  color: #fff !important;
  font-family: 'Gill Sans Nova' !important;
  // font-size: 18px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  font-size: 12px !important;
  text-transform: uppercase !important;
}

.lastUpdate {
  color: #000 !important;
  font-family: 'Myriad Pro' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  button {
    font-size: 12px !important;
  }
}

.description {
  overflow: hidden !important;
  //   color: #000 !important;
  color: #fff !important;
  text-overflow: ellipsis !important;
  font-family: 'Myriad Pro' !important;
  // font-size: 10px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  font-size: 18px !important;
}

.menuItem {
  color: #000 !important;
  font-family: 'Myriad Pro' !important;
  // font-size: 16px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  text-transform: capitalize !important;
}

@media (width<=500px) {
  .firstDiv {
    width: 100%;
  }
  .secDiv {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}

@media (width>=1800px) {
  .profileName {
    font-size: 32px !important;
  }
  .description {
    font-size: 24px !important;
  }
  .lastUpdate {
    button {
      font-size: 28px !important;
    }
  }
}
