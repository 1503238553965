.root {
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1) !important;
  display: flex;
  align-items: flex-start;
  gap: 15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  justify-content: space-between;
}

.firstDiv {
  display: flex;
  gap: 10px;
  width: auto;
}

.daterConvo {
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: flex-start;
}

.root:hover {
  background-color: #5c5b5f !important;
}

.profileName {
  // color: #000 !important;
  color: #fff !important;
  /* Heading */
  font-family: 'Gill Sans Nova' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  font-size: 12px !important;
}

.dater_profileName {
  // color: #000 !important;
  color: #fff !important;
  /* Heading */
  font-family: 'Myriad Pro' !important;
  font-style: normal !important;
  font-weight: 300 !important;
  line-height: normal !important;
  font-size: 18px !important;
  margin-left: 30px;
  // margin-top: 5px;
}
.friendOf {
  margin-left: -40px;
  font-family: 'Myriad Pro' !important;
  font-style: normal !important;
  font-weight: 300 !important;
  line-height: normal !important;
  text-wrap: nowrap;
  color: #76d9ff;
  font-size: 18px;
}
.date {
  color: #adafbb !important;
  text-align: right !important;
  font-family: 'Myriad Pro' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
}

.itrowStatus {
  font-family: 'Myriad Pro' !important;
  font-style: 'normal' !important;
  font-weight: 400 !important;
}

.introwStatus {
  font-family: 'Myriad Pro' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 18px !important;
}

.accptDeclineBtns {
  display: flex;
  gap: 10px;
}

@media (width>=1800px) {
  .profileName {
    font-size: 32px !important;
  }

  .introwStatus {
    font-size: 24px !important;
  }

  .date {
    font-size: 22px !important;
  }
}

@media (width<=800px) {
  .profileName {
    font-size: 12px !important;
    max-width: 60%;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: visible;
  }

  .date {
    max-width: 70%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .daterConvo {
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: flex-start;
    margin-left: 50px;
  }
  .friend {
    margin-left: 30px !important;
    // margin-right: 20px;
  }
  .friend_name {
    margin-top: 5px !important;
  }
}

// @media (min-width: 430px) and (max-width: 430px) {
//   .dater_profile {
//     display: 'flex';
//     justify-content: end;
//     margin-right: 15px;
//   }
// }
