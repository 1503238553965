.subHeader {
  // color:  #00597B !important;
  color: #76d9ff !important;
  /* Heading S 3 */
  font-family: 'Gill Sans Nova' !important;
  // font-size: 24px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  width: 100% !important;
  text-align: left !important;
}
.stackClass {
  // margin-top: 10px !important;
  // gap: 5px !important;
  font-family: 'Gill Sans Nova' !important;
  color: #cccccc !important;

}
.header{
  font-family: 'Gill Sans Nova' !important;

}
.description{
    font-family: 'Gill Sans Nova' !important;

}