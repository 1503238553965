// .imageHolder{
//     border-radius: 8px;
//     border: 2px dashed #BABABA;
//     background: #FFF;
// }
.uploadIcon{
    // top: 50% !important;
    // margin-top: 24px !important;
    position: relative;
    padding: 0% !important;
}
.badge{
    z-index: 100 !important;
    left: 100% !important;
    top: -5px !important;
    background-color: red !important;
    color: white !important;
    padding: 4px 8px !important;
    text-align: center !important;
    border-radius: 8px !important;
    position: relative !important;
}