.App {
  text-align: center;
}

@font-face {
  font-family: 'Myriad Pro';
  src: url("/assets/fonts/MyriadPro-Regular.otf");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'Roboto';
  src: url("/assets/fonts/Roboto-Regular.ttf");
  src: url("/assets/fonts/Roboto-Regular.woff2"); 
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.introwAppMobWrapper{
  max-height: 90vh;
  overflow-y: auto;
}

@font-face {
  font-family: 'Knuckle Down';
  src: url("/assets/fonts/knuckle down.otf");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Gill Sans Nova';
  src: url('./assets/fonts/gill-sans-nova.ttf');

}

@font-face {
  font-family: 'Gill Sans Nova';
  src: url('./assets/fonts/gillsansnova_ultrabold.ttf');
  font-weight: 900;

}

@font-face {
  font-family: 'Gill Sans';
  src: url('./assets/fonts/Gill Sans.otf');
}

.MuiOutlinedInput-notchedOutline{
  border: none !important;
}

.displayNone{
  display: none !important;
}
.cropper-point{
  pointer-events: none !important;
}

// @media (-webkit-device-pixel-ratio: 1.25) {
//   * {
//     zoom: 0.8;
//   }
// }




// @media (-webkit-device-pixel-ratio: 1.5) {
//   * {
//     zoom: 98%;
//    //transform: scale(0.9)
//   }
// }

body {
  #onesignal-slidedown-container {
    #onesignal-slidedown-dialog {
      .slidedown-body-message {
        font-family: 'Gill Sans Nova' !important;
        font-weight: 400 !important;
        font-size: 14px !important;
        line-height: 20.08px !important;
        letter-spacing: 0.06em !important;
        color: #44C8F5 !important;
        text-transform: uppercase !important;
        text-align: center !important;
        width: 100% !important;
      }
    }
    top: 50% !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  #onesignal-slidedown-dialog {
    transform: translateY(-50%) !important;
    border-radius: 10px !important;
  }

  .slidedown-body {
    padding-bottom: 20px !important;
  }

  .slidedown-body-message {
    font-family: 'Gill Sans Nova' !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 20.08px !important;
    letter-spacing: 0.06em !important;
    color: #44C8F5 !important;
  }

  .slidedown-button.primary {
    font-size: 14px !important;
    font-weight: 900 !important;
    text-transform: none !important;
    font-family: "Gill Sans Nova" !important;
    color: #ffffff !important;
    background-color: #e66433 !important;
    border-radius: 50px !important;
    padding: 8px 25px !important;
  }

  .slidedown-button.secondary {
    font-size: 14px !important;
    font-weight: 900 !important;
    text-transform: none !important;
    font-family: "Gill Sans Nova" !important;
    color: #ffffff !important;
    background-color: #BCBEC0 !important;
    border-radius: 50px !important;
    padding: 8px 25px !important;
  }

  #onesignal-slidedown-container #onesignal-slidedown-dialog .slidedown-button.secondary {
    box-shadow: none !important;
    background: #BCBEC0 !important;
    color: #ffffff !important;
    width: 140px !important;
    justify-content: center !important;
  }

  #onesignal-slidedown-container #onesignal-slidedown-dialog .slidedown-body {
    display: flex !important;
    box-sizing: border-box !important;
    margin: 0 !important;
    flex-direction: column !important;
    align-items: center !important;
  }

  #onesignal-slidedown-container #onesignal-slidedown-dialog .slidedown-body-icon img.default-icon {
    width: 25px !important;
    top: 3px !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
    position: absolute !important;
    height: 25px !important;
  }

  #onesignal-slidedown-container #onesignal-slidedown-dialog .slidedown-body-icon {
    box-sizing: border-box !important;
    float: left !important;
    width: 80px !important;
    height: 35px !important;
    position: relative !important;
  }

  #onesignal-slidedown-container #onesignal-slidedown-dialog .slidedown-footer {
    display: flex !important;
    position: relative !important;
    justify-content: space-around !important;
    flex-direction: row-reverse !important;
  }

  #onesignal-slidedown-container #onesignal-slidedown-dialog .primary.slidedown-button+.secondary.slidedown-button {
    margin-right: 1.714em!important;
  }

  #onesignal-slidedown-container #onesignal-slidedown-dialog {
    max-width: 400px !important;
  }

}
