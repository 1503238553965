// .button {
//   display: flex !important;
//   //padding: 8px 24px !important;
//   justify-content: center !important;
//   align-items: center !important;
//   //  gap: 10px !important;
//   border-radius: 33px !important;
//   color: #ffffff !important;
//   text-align: center !important;
//   /* InputLabel */
//   font-family: 'Gill Sans Nova' !important;
//   // font-size: 20px !important;
//   font-style: normal !important;
//   font-weight: 400;
//   // line-height: normal !important;
//   background-color: #77787b !important;
//   text-transform: uppercase !important;
//   line-height: 20.08px !important;
//   letter-spacing: 0.09em !important;
// }

.buttonSelected {
  // padding: 12px 32px !important;
  border-radius: 25px !important;
  background-color: #000 !important;
  color: #fff !important;
  border: 1px solid #E72933 !important;
  font-family: 'Gill Sans Nova' !important;
  //  font-size: 18px !important;
  font-style: normal !important;
  letter-spacing: 4% !important;
  font-weight: 900 !important;
  //  line-height: 24px !important; /* 133.333% */
  text-transform:none !important;
}

.button {
  // padding: 12px 32px !important;
  border-radius: 25px !important;
  background-color: #000 !important;
  color: #E66433 !important;
  border: 1px solid #E72933 !important;
  font-family: 'Gill Sans Nova' !important;
  //  font-size: 18px !important;
  font-style: normal !important;
  letter-spacing: 4% !important;
  font-weight: 900 !important;
  //  line-height: 24px !important; /* 133.333% */
  text-transform:none !important;
}

.buttonSelected2 {
  background-color: #2bb673 !important;
  color: #ffffff !important;
  text-transform: uppercase !important;
  display: flex !important;
  // padding: 8px 24px !important;
  justify-content: center !important;
  align-items: center !important;
  //  gap: 10px !important;
  border-radius: 33px !important;
  text-align: center !important;
  /* InputLabel */
  font-family: 'Gill Sans Nova' !important;
  // font-size: 20px !important;
  font-style: normal !important;
  font-weight: 400;
  // line-height: normal !important;
  text-transform: uppercase !important;
  line-height: 20.08px !important;
  letter-spacing: 0.09em !important;
}
