.nameAddressHeader {
  // color: #444 !important;
  color: #fff !important;
  text-align: center !important;
  /* Heading */
  font-family: 'Myriad Pro' !important;
  // font-size: 24px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  text-transform: capitalize !important;
}


.name {
  font-family: 'Gill Sans Nova' !important;
  font-size: 16px !important;
  font-weight: 900 !important;
  color: #e66433 !important;
  text-align: left !important;
  margin-left: 25px;
  line-height: 20.83px;
}

.ageAddress {
  font-family: 'Gill Sans Nova' !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #cccccc !important;
  text-align: left !important;
  line-height: 17.21px;
  letter-spacing: 0.02em;
}

