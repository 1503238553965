.main {
  // background-color: #FFFFFF !important;
  background-color: #000 !important;
  min-height: 100vh !important;
}

.footer {
  text-align: center;
  color: #33196b;
  //position: fixed;
  bottom: 0;
  align-items: center;
  // background-color: #FFFFFF;
  height: 30px;
  padding-top: 7px;
  width: 100%;
}
.footerLink {
  text-transform: none;
  text-decoration: none;
  color: #33196b;
  font-family: 'Myriad Pro';
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.03em;
}

.landing-page-layout-wrapper {
}
