.question{
    font-family: 'Gill Sans Nova' !important;
    font-style: normal !important;
    font-weight: 900 !important;
    line-height: normal !important;
    font-size: 18px !important;  
    letter-spacing: 2% !important;                               
}

// styles.scss
.dropdown_menu {
    border: 1px solid #C4C4C4;
    border-radius: 4px;
    background-color: #F1F6F8;
    position: absolute;
    z-index: 1;
    width: 100%;
    max-height: 200px; // adjust as needed
    overflow-y: auto;
  }
  
  .inputHeight {
    border-bottom: 1px solid #c4c4c4 !important;
    background: #D9D9D9 !important;
    height: 40px !important;
    //padding-left:16px;
    font-family: 'Gill Sans Nova' !important; // Changed font family to 'Gill Sans Nova'
    font-size: 16px !important; // Changed font size to 10px
    font-style: normal !important;
    font-weight: 500 !important; // Changed font weight to 500
    line-height: 14.34px !important; // Changed line height to 14.34px
    letter-spacing: 10% !important; // Added letter spacing of 10%
    border-radius: 50px !important;
    color: #000 !important;
  }

@media (width>=1800px){
    .question{
        font-size: 32px !important;
    }
}