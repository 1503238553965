.root {
  border-radius: 16px;
}

.heading {
  //   color: #05232e !important;
  color: #fff !important;
  /* Large Lebel */
  font-family: 'Myriad Pro' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
}
.date {
  // color: #7a7a7a !important;
  color: #fff !important;
  // font-family: 'Myriad Pro' !important;
  font-family: 'Gill Sans Nova' !important;
  font-style: normal !important;
  font-weight: 300 !important;
  line-height: 16px;
}

.content {
  // color: #05232E !important;
  color: #FFFFFF !important;
  

  /* P */
  font-family: 'Gill Sans Nova' !important;

  font-style: normal !important;
  font-weight: 600 !important;
}
.notificationItemBody {
  width: 95%;
}
@media (width<=900px) {
  .notificationItemBody {
    width: 100% !important;
  }
}
@media (width>=1800px) {
  .heading {
    font-size: 32px !important;
  }
  .content {
    font-size: 24px !important;
  }
  .date {
    font-size: 24px !important;
  }
  .introwStatus {
    img {
      height: 48px !important;
      width: 48px !important;
    }
  }
}
