.elbow-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.elbowFirstDiv-div1{
    display: flex;
    align-items: center;
    gap: 10px;
  }
.elbowSecDiv{
    width: 50%;
    display: flex;
    justify-content: flex-end;
    gap: 5px;
    
}
.elbowFirstDiv{
    // width: 45%;
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;

}
.YesNoGrid{
    display: flex;
    gap: 2px;
    align-items: center;
    button{
        font-size: 24px !important;
    }
}
@media (width<=770px){
    .elbow-container{
        display: flex;
        flex-direction: column !important;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
    }
    .elbowSecDiv{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        gap: 5px;
        
    }
    .elbowFirstDiv{
        width: 100%;
        display: flex;
        align-items: center;
        // justify-content: center;
        justify-content: space-between;
        gap: 5px;
    }
}

@media (width>=1800px){    
    .YesNoGrid{
        button{
            font-size: 32px !important;
        }
    }
}