.description {
  // color: #333 !important;
  color: #fff !important;
  font-family: 'Gill Sans Nova' !important;
  // font-size: 24px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  // line-height: 32px !important; /* 133.333% */
  line-height: 17.21px;
  letter-spacing: 0.12em;
  text-transform: uppercase;
}
