.galleryContainer{
    position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  // width: 360px !important;
  width:400px !important;
  background-color: #F3F3F3 !important;
  border: 1px solid #F3F3F3 !important;
  box-shadow: 24 !important;
  font-family: 'Myriad Pro' !important;
  //padding: 4px !important;
}

.button{
    font-family: 'Myriad Pro' !important;
    font-size: 18px !important; 
    font-weight: 600 !important;
}