.nameAddressHeader {
  //   color: #444 !important;
  color: #E66433 !important;
  /* Heading */
  font-family: 'Gill Sans Nova' !important;
  // font-size: 24px !important;
  font-style: normal !important;
  font-weight: 900 !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
line-height: 20.83px !important; 
text-align: left ;

}
.profilepicContainer {
  // max-width: 500px !important;
  width: 30%;
  img {
    width: 100%;
  }
}
.profileDataContainer {
  width: 68%;
}
.friendHeader{
  min-height: 40px !important;
}

.subHeader {
  // color:  #00597B !important;
  // color: #fff !important;
  color: #76d9ff !important;
  /* Heading S 3 */
  font-family: 'Knuckle Down' !important;
  // font-size: 24px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  width: 100% !important;
  font-size: 24px !important;
}
.details {
  //   color: #00597b !important;
  color: #fff !important;
  /* Heading S 3 */
  font-family: 'Myriad Pro' !important;
  // font-size: 24px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  width: 100% !important;
  word-break: break-word;
  font-size: 20px !important;
}
.description {
  //   color: #00597b !important;
  color: #fff !important;
  /* Heading S 3 */
  font-family: 'Myriad Pro' !important;
  // font-size: 24px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  width: 100% !important;
}
.error {
  color: #ff0000 !important;
  line-height: 17.21px !important;
  letter-spacing: 0.12em !important;
  margin-left: 12px !important;



font-family: 'Myriad Pro' !important;
  //font-size: 18px !important;
  font-style: italic !important;
  font-weight: 400 !important;
  //line-height: 40px !important; /* 166.667% */
}
.friendProfileWrapper {
  display: flex;
  gap: 10px;
}

@media (width>=1800px) {
  .nameAddressHeader {
    font-size: 48px !important;
  }
  .subHeader {
    font-size: 32px !important;
  }
}

.profilepicContainer {
  // max-width: 500px !important;
  width: 34%;

  img {
    width: 100%;
  }
}

.profileDataContainer {
  width: 64%;
}
.friendPic {
  height: 400px;
}

.subHeader {
  // color: #00597b !important;
  // color: #fff !important;
  color: #76d9ff !important;
  /* Heading S 3 */
  font-family: 'Knuckle Down' !important;
  // font-size: 24px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  width: 100% !important;
  font-size: 24px !important;
}

.details {
  // color: #00597b !important;
  color: #fff !important;
  /* Heading S 3 */
  font-family: 'Myriad Pro' !important;
  // font-size: 24px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  width: 100% !important;
  word-break: break-word;
  font-size: 20px !important;
}

.description {
  // color: #00597b !important;
  color: #fff !important;
  /* Heading S 3 */
  font-family: 'Myriad Pro' !important;
  // font-size: 24px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  width: 100% !important;
}

.error {
  color: #ff0000 !important;
  line-height: 17.21px !important;
  letter-spacing: 0.12em !important;
  margin-left: 12px !important;



font-family: 'Myriad Pro' !important;
  //font-size: 18px !important;
  font-style: italic !important;
  font-weight: 400 !important;
  //line-height: 40px !important; /* 166.667% */
}

.friendProfileWrapper {
  display: flex;
  gap: 10px;
}

@media (width>=1800px) {
  .nameAddressHeader {
    font-size: 48px !important;
  }

  .subHeader {
    font-size: 32px !important;
  }
}

@media (width<=1150px) {
  .profilepicContainer {
    // max-width: 500px !important;
    width: 100%;

    img {
      width: 100%;
    }
  }

  .profileDataContainer {
    width: 100%;
  }

  .friendPic {
    height: 100% !important;
  }
}

@media (width>=1800px) {
  .details {
    font-size: 28px !important;
  }

  .submitBtn {
    button {
      font-size: 24px !important;
    }
  }
}

@media (width >=2200px) {
  .profilepicContainer {
    width: 20%;

    img {
      width: 100%;
    }
  }

  .profileDataContainer {
    width: 78%;
  }
}

@media (width>=900px) {
  .friendHeader{
    min-height: 80px !important;
  }
}