.button {
  display: flex !important;
  //padding: 8px 24px !important;
  justify-content: center !important;
  align-items: center !important;
  //  gap: 10px !important;
  border-radius: 33px !important;
  color: #ffffff !important;
  text-align: center !important;
  /* InputLabel */
  font-family: 'Gill Sans Nova' !important;
  // font-size: 20px !important;
  font-style: normal !important;
  font-weight: 400;
  // line-height: normal !important;
  background-color: #77787b !important;
  text-transform: uppercase !important;
  line-height: 20.08px !important;
  letter-spacing: 0.09em !important;
}

.buttonSelected {
  background-color: #2bb673 !important;
  color: #ffffff !important;
  text-transform: uppercase !important;
  display: flex !important;
  // padding: 8px 24px !important;
  justify-content: center !important;
  align-items: center !important;
  //  gap: 10px !important;
  border-radius: 33px !important;
  text-align: center !important;
  /* InputLabel */
  font-family: 'Gill Sans Nova' !important;
  // font-size: 20px !important;
  font-style: normal !important;
  font-weight: 400;
  // line-height: normal !important;
  text-transform: uppercase !important;
  line-height: 20.08px !important;
  letter-spacing: 0.09em !important;
}
