@media(width>=1800px){
    .privacyTxt{
        font-size: 24px !important;
        line-height: 45px !important;
    }
    .privacySecHead{
        font-size: 32px !important;
    }
    .privacyTxtHead{
        font-size: 28px !important;
    }
    .privacyHead2{
        font-size: 26pt !important;
    }
    .anchorCls{
        font-size: 28px !important;
    }
    .privacySubTxt{
        font-size: 26pt !important;
    }
}