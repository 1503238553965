.back {
  color: #cccccc !important;
  font-family: 'Gill Sans Nova' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  text-transform: uppercase;
  cursor: pointer;
  line-height: 17.21px;
  letter-spacing: 0.1em;
}
.heading {
  // color:  #00597B !important;
  // color: #fff !important;
  color: #ECA43C !important;
  font-family: 'Gill Sans Nova' !important;
  // font-size: 32px !important;
  font-style: normal !important;
  font-weight: 900 !important;
  line-height: normal !important;
  font-size: 24px !important;
  margin-left: 10px !important;
}
.steps {
  color: #FFFFFF !important;
  font-family: 'Gill Sans Nova' !important;  //  font-size: 32px !important;
  font-style: normal !important;
  font-weight: 500 !important;
   line-height: 24px !important; /* 125% */
  text-transform: uppercase !important;
  font-size: 18px !important;
  letter-spacing: 0.09em !important;
}
.stepsCounter {
  color: #FFFFFF !important;
  font-family: 'Gill Sans Nova' !important;  //  font-size: 32px !important;
  font-style: normal !important;
  font-weight: 500 !important;
   line-height: 24px !important; /* 125% */
  text-transform: uppercase !important;
  font-size: 24px !important;
  letter-spacing: 0.09em !important;
}
.then {
  color: #BCBEC0 !important;
  text-transform: uppercase !important;
  font-family: 'Gill Sans Nova' !important;  // font-size: 32px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 24px !important; /* 200% */
  letter-spacing: 0.06em;
}
.smallText {
  color: #BCBEC0 !important;
  text-transform: uppercase !important;
  font-family: 'Gill Sans Nova' !important;  // font-size: 32px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  letter-spacing: 0.06em !important;
}
