.menuItemText {
  span {
    font-size: 18px !important;
  }
}

.childDrawer {
  max-height: calc(100vh - 80px);
  overflow-y: auto;
}

@media (width>=1800px) {
  .menuItemText {
    span {
      font-size: 24px !important;
    }
  }
}
