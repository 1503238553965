.description {
  color: #494949 !important;
  font-family: 'Myriad Pro' !important;
  //font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.smallCaseBold {
  font-weight: 700 !important;
  color: #494949 !important;
  font-family: 'Myriad Pro' !important;
  // font-size: 14px !important;
  font-style: normal !important;
}

.capitalCaseBold {
  color: #494949 !important;
  font-family: 'Myriad Pro' !important;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
}
.pageName {
  text-align: left !important;

  /* Heading S 2 */
  font-family: 'Gill Sans Nova' !important;
  //font-size: 32px !important;
  font-style: normal !important;
  font-weight: 900 !important;
  line-height: normal !important;
}
.main {
  //max-height: 50vh !important;
  // overflow: auto;
  color: #fff !important;
  margin-right: 20px;
}

.back {
  // color:  #00597B !important;
  color: #cccccc !important;
  font-family: 'Gill Sans Nova' !important;
  // font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  text-transform: uppercase;
  // padding-left: 10px !important;
  // padding-bottom: 5px !important;
  cursor: pointer;

  line-height: 17.21px;
  letter-spacing: 0.1em;
}

.logoBack{
  margin-top: 6px;
  color: #cccccc;
  height: 12px !important;
  width: 12px !important;
}
@media (width>=1800px) {
  .pageName {
    font-size: 42px !important;
    line-height: unset !important;
  }
  .privacyTxt {
    font-size: 24px !important;
  }
}
