.description {
  // color: #00597b !important;
  // color: #fff !important;
  color: #76d9ff !important;
  font-family: 'Knuckle Down' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.error {
  color: #ff0000 !important;
  line-height: 17.21px !important;
  letter-spacing: 0.12em !important;
  margin-left: 12px !important;



font-family: 'Myriad Pro' !important;
  // font-size: 18px !important;
  font-style: italic !important;
  font-weight: 400 !important;
  // line-height: 40px !important; /* 166.667% */
  text-align: left !important;
}
