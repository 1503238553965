.root {
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1) !important;
  display: flex;
  align-items: flex-start;
  gap: 15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  justify-content: space-between;
}
.root:hover {
  //   background-color: #f2f2f2 !important;
  background-color: #adafbb9d !important;
}
.convosFirstDiv {
  // display: flex;
  // align-items: center;
  gap: 5px;
  // width: 60%;
}
.profileName {
  max-width: 70px;
  color: #fff !important;
  font-family: 'Gill Sans Nova' !important;
  font-weight: 900 !important;
  font-size: 12px !important;
  line-height: 16.22px !important;
}

.age {
  font-family: 'Gill Sans Nova' !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 14.34px !important;
  color: #808184;
}

.date {
  color: #adafbb !important;
  text-align: right !important;
  font-family: 'Myriad Pro' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
}
.introwStatus {
  font-family: 'Myriad Pro' !important;
  font-style: 'normal' !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  color: #fff;
}

.friendOf {
  font-family: 'Gill Sans Nova' !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 14.34px !important;
  letter-spacing: 0.16em !important; // Adjusted to 16%
  color: #808184;
  text-transform: uppercase;
}

.elbowStatus {
  font-family: 'Myriad Pro' !important;
  font-size: 16px;
  font-style: normal !important;
  font-weight: 400;
  line-height: 24px;
}

@media (width>=1800px) {
  .elbowStatus {
    font-size: 24px !important;
  }
  .profileName {
    font-size: 32px !important;
  }
  .introwStatus {
    font-size: 20px !important;
  }
  .date {
    font-size: 20px !important;
  }
}
