// @import url('../../config.scss');

// :root {
//         --delta: 0;
//     }
// @media (-webkit-device-pixel-ratio: 1) {
//     :root {
//         --delta: 0;
//     }
// }
// @media (-webkit-device-pixel-ratio: 1.25) {
//     :root {
//         --delta: 0.25;
//     }
// }

// @function getDelta(){
//     @return var(--delta);
// }

// @function scaleValue($value)
//     {
//         @return calc($value - (#{getDelta()} * $value));
//     }

// @function scaleValue(
//   $value
// ) {
//     @return $value;
// }

// @function scaleValue(
//     $value
//     ) {
//         @return calc($value - ($delta * $value));
//     }

.header {
  // background-color: #FFFFFF !important;
  background-color: #000 !important;
  color: #fff !important;
  box-shadow: none !important;
}
.appIcon {
  // padding-top: 24px;
  // padding-bottom: 24px;
  cursor: pointer !important;
  // height: 48px !important;
}
.leftMenuAppIcon {
  height: 73px;
  cursor: pointer !important;
}
.menuContainer {
  flex-direction: row-reverse;
}
.headerBox {
  max-height: 60px;
  height: 60px;
  min-height: 0px !important;
}
.menuItem {
  //   color: #33196b !important;
  // color: #fff !important;
  color: #76d9ff !important;

  font-family: 'Myriad Pro' !important;
  // font-size: 20px !important;
  font-weight: 600 !important;
  // line-height: 24px !important;
  letter-spacing: 0.03em !important;
}
.btnJoin {
  border-radius: 50px !important;
  border: 1px solid #ff9933 !important;
  width: 101px !important;
  color: #33196b !important;
  font-family: 'Myriad Pro' !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  letter-spacing: 0.03em !important;
  text-transform: none !important;
}
